<template>
  <form
    @submit.prevent="$router.push({ name: route, query: { search } })"
    class="search-form"
  >
    <input v-model="search" :placeholder="placeholder" class="form-control" />
    <button type="submit">
      <i class="fa-regular fa-search"></i>
    </button>
  </form>
</template>

<script>
export default {
  props: {
    placeholder: String,
    route: String,
  },
  data() {
    return {
      search: this.$route.query.search || "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.search-form {
  position: relative;

  input {
    width: 100%;
    border-radius: 4px;
    background-color: $dark;
    color: white;
    border: none;
    min-height: 43px;
  }

  button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
  }
}
</style>
