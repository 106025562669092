<template>
  <div class="forum-topic-categories">
    <action-dispatcher action="forum/getCategories">
      <h6 class="mt-4">Categorias</h6>
      <router-link
        :to="{
          name: 'forum',
          query: {},
        }"
        class="btn"
        :class="{
          'btn-primary': $route.name === 'forum' && !$route.query.category,
          'btn-dark': $route.name !== 'forum' || !!$route.query.category,
        }"
      >
        Todas
      </router-link>

      <router-link
        v-for="category in categories"
        :key="category.id"
        :to="{
          name: 'forum',
          query: { category: category.id },
        }"
        class="btn"
        :class="{
          'btn-primary': $route.query.category === '' + category.id,
          'btn-dark': $route.query.category !== '' + category.id,
        }"
      >
        {{ category.name }}
      </router-link>
    </action-dispatcher>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("forum", ["categories"]),
  },
};
</script>

<style lang="scss" scoped>
.forum-topic-categories {
  .btn {
    display: block;
    width: 100%;
    margin-top: 16px;
    text-align: left;
    font-weight: normal;
    padding: 12px;
  }
}
</style>
