<template>
  <div class="forum-topic">
    <div class="flex-fill">
      <div class="text-light text-decoration-none">
        <div class="d-flex align-items-center mb-3">
          <user-photo class="me-2" size="40" :photo="topic.user.picture" />

          <div class="d-flex flex-column overflow-hidden text-secondary">
            <h6 class="mb-0 text-light">
              {{ topic.user.name }}
            </h6>
            <small class="text-secondary fw-normal">
              {{ time }}
              <template v-if="topic.category">
                <i class="fa-solid fa-circle-small mx-1"></i>
                {{ topic.category.name }}
              </template>
            </small>
          </div>
        </div>

        <h5>{{ topic.name }}</h5>

        <div class="topic-text-wrapper" :class="{ open }">
          <div
            class="mb-2 topic-text"
            v-html="topic.description"
            :id="`topic-text-${topic.id}`"
          />
          <button v-if="!open" @click="open = true">Ver mais</button>
        </div>

        <img
          v-if="topic.cover"
          class="img-fluid topic-cover mb-2"
          :src="topic.cover"
        />
      </div>

      <div class="d-flex">
        <button
          class="btn btn-sm p-0 me-3"
          :class="{ 'text-primary': liked, 'text-secondary': !liked }"
          @click="like"
        >
          <i
            class="fa-heart me-1 fa-lg"
            :class="{ 'fa-solid': liked, 'fa-regular': !liked }"
          ></i>
          {{ likeCount }} curtida{{ likeCount === 1 ? "" : "s" }}
        </button>

        <button
          class="btn btn-sm text-secondary"
          @click="
            $router.push({
              name: 'forum-topic',
              params: { id: topic.id, slug: topic.slug },
            })
          "
        >
          <i class="fa-regular fa-comment fa-lg me-2"></i>
          <span
            >{{ topic.count }} comentário{{
              topic.count === 1 ? "" : "s"
            }}</span
          >
        </button>
      </div>
    </div>

    <el-dropdown v-if="topic.is_owner" trigger="click" class="el-dropdown">
      <span class="el-dropdown-link">
        <i class="fa-solid fa-ellipsis-vertical text-light fa-lg p-1"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="$emit('edit', topic)">
            Editar
          </el-dropdown-item>
          <el-dropdown-item @click="$emit('remove', topic)">
            Remover
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/pt";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.locale("pt");

export default {
  name: "topic",
  props: {
    topic: Object,
    link: Boolean,
  },
  data() {
    return {
      liked: this.topic.liked,
      likeCount: this.topic.like || 0,
      open: true,
    };
  },
  computed: {
    time() {
      return dayjs().to(dayjs(this.topic.created_at).add(3, "hour"));
    },
  },
  mounted() {
    const element = document.getElementById(`topic-text-${this.topic.id}`);
    const height = element.offsetHeight;
    if (height > 300) {
      this.open = false;
    }
  },
  methods: {
    like() {
      this.liked = !this.liked;
      this.$store
        .dispatch("forum/interact", {
          object_id: this.topic.id,
          like: this.liked,
          type: "topic",
        })
        .then(() => {
          if (this.liked) this.likeCount++;
          else this.likeCount--;
        })
        .catch(() => {
          this.liked = !this.liked;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.forum-topic {
  background: $dark;
  border-radius: 4px;
  padding: 16px 16px 10px;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  .topic-text {
    ::v-deep * {
      white-space: pre-wrap;
      text-wrap: wrap;
      color: white;
    }
    
    ::v-deep img {
      max-width: 100%;
    }
  }

  .topic-text-wrapper {
    position: relative;

    &:not(.open) .topic-text {
      max-height: 300px;
      overflow: hidden;
    }

    button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 100px 0 8px;
      border: none;
      background: linear-gradient(to bottom, transparent, $dark 90%);
      color: $primary;
      font-weight: bold;
    }
  }

  .topic-cover {
    max-height: 300px;
  }
}
</style>
