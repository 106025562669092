<template>
  <div class="forum-top-topics">
    <action-dispatcher action="forum/getLikedTopics">
      <h6 class="mt-5">Tópicos mais curtidos</h6>
      <router-link
        v-for="topic in likedTopics"
        :key="topic.id"
        :to="{
          name: 'forum-topic',
          params: { id: topic.id, slug: topic.slug },
        }"
        class="btn btn-dark"
      >
        {{ topic.name }}
      </router-link>
    </action-dispatcher>

    <action-dispatcher action="forum/getCommentedTopics">
      <h6 class="mt-5">Tópicos mais comentados</h6>
      <router-link
        v-for="topic in commentedTopics"
        :key="topic.id"
        :to="{
          name: 'forum-topic',
          params: { id: topic.id, slug: topic.slug },
        }"
        class="btn btn-dark"
      >
        {{ topic.name }}
      </router-link>
    </action-dispatcher>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("forum", ["likedTopics", "commentedTopics"]),
  },
};
</script>

<style lang="scss" scoped>
.forum-top-topics {
  .btn {
    display: block;
    width: 100%;
    margin-top: 16px;
    text-align: left;
    font-weight: normal;
    padding: 12px;
  }
}
</style>
